.kanban-container {
  // overflow-x: auto;
  scroll-behavior: smooth;
  margin-top: 2rem;

  >.row {
    flex-wrap: nowrap;
    margin-left: -.25rem;
    margin-right: -.25rem;

    [class*="col-"] {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }
}

.kanban-column {
  background-color: #6F6B7405;

  &.highlight {
    background-color: #6F6B7405;
  }

  border: none;
  border-radius: .5rem;
  width: 18rem;
  /* For webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 10px; /* Adjust width of scrollbar */
  }

  ::-webkit-scrollbar-track {
      background: none; /* Default background of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
      background: #888; /* Default background of the scrollbar thumb */
      border: 10px solid #ddd; /* Border of the scrollbar thumb */
      border-radius: 8px; /* Rounded corners for the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555; /* Background of the scrollbar thumb on hover */
  }

  >.card-header {
    background-color: rgb(111, 107, 116, 0.08);
    padding: 23px 32px;
    border-bottom-color: $gray-19;
  }

  >.card-footer {
    border-top-color: $gray-19;
    padding: .5rem 0.75rem;
  }

  >.card-body {
    // padding: .5rem;
  }

  &:hover {
    .not-assigned {
      opacity: 1;
    }
  }
}

.has-icon-left {
  position: relative;
  padding-left: 1.5rem;

  .icon-left-center {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.card-fixed-header {
  >.card-body {
    height: calc(100vh - 9rem);
    overflow-y: auto;
  }

  .tab-content {
    position: relative;
    height: calc(100vh - 9rem);
    overflow-y: auto;
  }

  .tab-pane {
    height: 100%;

    .kanban-category {
      padding: .5rem;
    }
  }

  .kanban-category {
    min-height: 100%;
  }
}

.kanban-item {
  margin-bottom: .5rem;
}

.not-assigned {
  opacity: 0;
  font-size: 0.6875rem;
  padding: .625rem;
  background-color: rgba($black, .04);
  border-radius: .25rem;
}

.drop-card {
  border: .0625rem $blue-15;
  background-color: rgba($black, .02);
  min-height: 5rem;
}

.text-limit-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.frosted-effect {
  position: absolute;
  width: calc(100%);
  height: 100%;
  left: 50%;
  top: 0;
  z-index: 10;
  transform: translateX(-50%);
  border: .125rem dashed $primary;
  border-radius: .5rem;
  backdrop-filter: blur(1rem);
  background: rgba($black, 0.02);
}

@media (min-width: 375px) {
  .kanban-column {
    width: 21.75rem;
  }
}


@media (min-width: 992px) {
  .kanban-container {
    margin-top: 0rem;

    >.row {
      .col-auto:nth-child(1) {
        padding-right: .75rem;
      }

      .col-auto:nth-child(2) {
        padding-left: .75rem;
      }
    }
  }

  .kanban-column {
    width: 20rem;
  }

  .card-fixed-header {
    >.card-body {
      height: calc(100vh - 12rem);
    }

    .tab-content {
      height: calc(100vh - 12rem);
    }
  }

}

@media (max-width:1024px) {
  .kanban-container {
    overflow: auto;
  }
}


.column-border-highlight {
  border: 1px solid $blue-15;
}

.column-border-dashed {
  border: 2px dashed $blue-15;
}