.custom-tabs{
  .nav-tabs{
    border-bottom-color: $gray-20;
    margin: 0 .5rem;
    .nav-link{
      font-size: $font-size-sm;
      font-weight: $font-weight-semi-bold;
      color: $secondary;
      padding: 1rem 0.5rem 0.5rem;
      border: 0;
      background-color: transparent;
      text-align: center;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: .1875rem;
        border-radius: .125rem;
      }
      .badge{
        font-size: 0.6875rem;
        padding: 0.125rem 0.375rem;
        min-width: 1.25rem;
        line-height: 1rem;
        color: $secondary;
      }
      &.active{
        &:after{
          background-color: $green-16;
        }
        color: $green-16;
        .badge{
          color: $green-16;
        }
      }
    }
  }
  .nav-item{
    flex: 1 1 auto;
  }
}