// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;



//color
$black-9: #1C1C1E;
$black-10:#6E6E53;
$black-11: #323338;
$black-12: #636366;
$black-13: #A9ADBD;
$black-14: #2E3538;
$black-15: #303436;
$black-16:#2B363B;

$red-12:#D10000;
$red-13: #FF6250;
$red-14: #ffb3b6;
$red-15: #ffd6d8;
$red-dark-light:#FFF0F1;

$orange-light: #FCE9DB;

$blue-11: #33A3D3;
$blue-12: #3478B7;
$blue-13: #1E6FBE;
$blue-14: #3A86CB;
$blue-15: #1672B8;

$green-light: #E6E9EF;
$green-11: #205758;
$green-12: #57BD83;
$green-13: #F5FBF8;
$green-14: #DDF2E6;
$green-15: #EDF1F3;
$green-16: #4EAA76;

$yellow-11: #CECEB8;
$yellow-12: #F19249;
$yellow-13: #F5B546;

$gray-11: #c5c7d0;
$gray-12: #E6E9EF;
$gray-13: #F5F6F8;
$gray-14: #C5C7D0;
$gray-15: #F5F7F8;
$gray-16: #F2F2F7;
$gray-17: #D1D1D6;
$gray-18: #767680;
$gray-19: #E6ECEF;
$gray-20: #C5CBD0;
$gray-21:#5C6970;
$gray-22: #8F9CA3;
$gray-23:#5A6A72;
$gray-24:#8F9CA3;
$gray-25:#8D9DA5;
$white: #FFFFFF;
$white-2: #F2F6F8; //color overrides


//font size
$font-size-sm: .875rem;
$font-size-base: 1rem;
$font-size-xs: .75rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-xsm: 0.688rem;
//font weight
$font-weight-400 :400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;
$font-weight-200: 200;
$font-weight-300: 300;