.form-control {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.75rem;
  border-radius: .25rem;
  color: rgba($black, .6);
  background-color: $white;
  border-color: $gray-20;

  &:not([readonly]):focus {
    color: rgba($black, .6);
    background-color: $white;
    box-shadow: none;
    border-color: $blue-15;
    outline: .0625rem solid $blue-15;
  }

  &[readonly] {
    background-color: $gray-15;
    border-color: $gray-15;
    box-shadow: none;
  }

  &.textarea {
    min-height: 5rem;
  }

  &.h40 {
    height: 2.5rem;
  }

  @include placeholder($black-13, 400);
}

input[type="search"] {
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

//
//.form-control-gray{
//
//
//  &:not([readonly]):focus{
//    background-color: rgba($gray-18,0.05);
//  }
//}

.input-ghost {
  .form-control {
    padding-left: 2.875rem;
    border-color: transparent;
    @include placeholder($gray-22, $font-weight-normal);

    &:hover {
      border-color: $gray-20;
    }

    &:focus {
      border-color: $blue-15;
      ;
    }
  }
}

.loginWrap {
  max-width: 21.375rem;
  margin: 11.75rem auto 0;
}



input[type="file"] {
  display: none;
}

.fileUpload input.upload {
  display: inline-block;
}