.btn {
  height: 3rem;
  border-radius: .375rem;
  font-weight: $font-weight-medium;

  &:focus {
    box-shadow: none;
  }
}

.btn-link {
  text-decoration: none;
  height: auto;
  padding: 0;
  border: 0;
}

.btn-sm {
  font-size: $font-size-xs;
  border-radius: .25rem;
  height: 2rem;
  letter-spacing: 0.03125rem;
}

.btn-md {
  font-size: $font-size-sm;
  border-radius: .25rem;
  height: 2.5rem;
}


.btn-icon-left {
  position: relative;
  padding-left: 2.875rem;

  .icon-left {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-dark {
  @include button-variant($black, $black, $hover-background: darken($black, 7.5%), $hover-border: darken($black, 10%), $active-background: darken($black, 10%), $active-border: darken($black, 12.5%));

  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $white !important;
    background-color: rgba($black, .15) !important;
    border-color: rgba($black, .15) !important;
    opacity: 1;
  }
}

.btn-success {
  color: $white;

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
  }
}

.btn-danger {
  color: $white;

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    color: white;
  }
}

.show {
  >.btn-success {
    &.dropdown-toggle {
      color: $white;
    }
  }
}

.btn-light {
  @include button-variant($white, $gray-20, $hover-background: $gray-15, $hover-border: darken($gray-15, 15%), $active-background: $green-15, $active-border: darken($gray-15, 15%));
  color: $secondary;

  &:hover,
  &:active,
  .show>dropdown-toggle:active {
    color: $black-14;
  }
}

.btn-group {
  &.btn-group-sm {
    .btn {
      font-size: $font-size-base;
    }
  }

  .btn-white {
    border-color: $gray-19;
    color: $secondary;

    &.active {
      background-color: $gray-19;
      color: $success;
    }
  }
}

.btn-group {
  &.dropdown-split {
    .dropdown-toggle {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      min-width: 3rem;
      overflow: hidden;

      .has-split {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($black, .16);
      }
    }

    .custom-dropdown {
      .dropdown-toggle {
        padding-right: .875rem;
      }
    }
  }
}

.floating-btn-left {
  width: 2rem;
  height: 2rem;
  left: 0.2rem;
  position: absolute;
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.floating-btn-right {
  width: 2rem;
  height: 2rem;
  position: absolute;
  border-radius: 50%;
  padding: 0.25rem;
  right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.checkbox-active {
  width: 0.85rem;
  height: 0.85rem;
  font-style: normal;
  // font-weight: $font-weight-400;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  background-color: rgba($green-12, .4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  width: 0.85rem;
  height: 0.85rem;
  font-style: normal;
  font-weight: $font-weight-400;
  font-size: 16px;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  text-align: center;
  border: 0.12rem solid $gray-23;
  border-radius: 0.2rem;
}

.radio-button {
  width: 0.85rem;
  height: 0.85rem;
  font-style: normal;
  font-weight: $font-weight-400;
  font-size: 16px;
  line-height: 0.75rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 0.12rem solid $gray-23;
  border-radius: 50%;
}

.radio-button-inner-circle {
  // width: 0.5rem;
  // height: 0.5rem;
  // border-radius: 50%;
  // border: 0.2rem solid $gray-23;
}

.radio-button-active {
  width: 0.85rem;
  height: 0.85rem;
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  background-color: rgba($green-12, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.signeLine {
  width: 70%;
  height: 10%;
  border-radius: 5%;
  background-color: $green-12;
}