.toast-success-container-after {
    overflow: hidden;
    position: relative;
}

.toast-success-container-after::after {
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: #34A853;
}

.toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    // border: 1px solid #34A853 !important;
    padding-left: 5rem;
    box-shadow: 0rem 1rem 5rem rgba(248, 175, 175, 0.1) !important;
}

.toastify__close {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}



.toast-icon {
    padding-right: 0.4rem;
}

.Toastify__toast-body {
    background-color: transparent;
}

.toast-body-container {}