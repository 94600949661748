.table {
  border-collapse: separate;
  -webkit-border-vertical-spacing: .5rem;
  -webkit-border-horizontal-spacing: 0;

  th {
    font-size: $font-size-sm;
  }

  td {
    font-size: $font-size-sm;
    border-bottom-color: $gray-19;
    padding-bottom: 0;
    padding-top: 0;
    height: 3rem;
  }

  > :not(:first-child) {
    border-top: .0625rem solid $gray-20;
  }

  tbody {
    tr {
      &:hover {
        background-color: $gray-19;
      }
    }
  }
}

.table-container {
  margin-top: 1.8rem;
}

@media (min-width:992px) {

  .table-container {
    margin-top: 0rem;
  }
}

.table-description {
  max-width: 16rem;
}

.table-pagination {
  width: 98%;
  display: flex;
  justify-content: flex-end;
}