.main-settings-container {
    display: flex;

    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 3rem;
}

.notification-settings-container {
    padding: 1rem;
    background-color: white;
    border-radius: 0.75em;
    width: 100%;
    height: auto;
}


@media (min-width:992px) {
    .main-settings-container {
        width: 60%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 2.625rem;
    height: 1.25rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.094rem;
    bottom: 0.125rem;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $green-12;
}

input:focus+.slider {
    box-shadow: 0 0 1px $green-12;
}

input:checked+.slider:before {
    -webkit-transform: translateX(1.375rem);
    -ms-transform: translateX(1.375rem);
    transform: translateX(1.375rem);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}