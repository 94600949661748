$path-to-fonts: '../../../../public/fonts' !default;
$path-to-img: '/img' !default;
$success: #57BD83;
$dark: #303436;
$white: #fff;
$black: #000;
$secondary: #5C6970;
$primary: #3A86CB;
$danger: #e2412e;

$grey-base: #6F6B74;
$grey-base-light: #6F6B7480;
$grey-12: #ccc;
$grey-light: rgba(111, 107, 116, 0.06);
$grey-light-8: rgba(111, 107, 116, 0.08);
$grey-light-10: rgba(111, 107, 116, 0.1);
$grey-light-12: rgba(111, 107, 116, 0.5);

$body-bg: #FFFFFF;
$border-color: #C5C7D0;
$table-th-font-weight: 500;
//font family
$public-sans: 'Public Sans';
$font-family-base: $public-sans;

//body
$body-color: $secondary;
//heading
$headings-color: $dark;