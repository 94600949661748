.card {
  border-color: $gray-19;
}

.card-body {
  padding: .75rem 1rem;
}

.card-footer {
  background-color: transparent;
}

.loading-card {
  background-color: rgba($color:$gray-12, $alpha: 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}