.asset-wrap {
  margin-left: -.25rem;
  margin-right: -.25rem;

  .avatar {
    margin: 0 .25rem 1rem;
    border-radius: .25rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // border-radius: 10px;
    }
  }

  .avatar-60 {
    width: 3.75rem;
    height: 3.75rem;
  }



  .avatar-bordered {
    border: 1px solid $gray-20;
  }
}

.avatar-80 {
  width: 5rem;
  height: 5rem;
  border-radius: 0.25rem;
}




@media (min-width: 420px) {
  .avatar-80 {
    width: 5rem;
    height: 5rem;
    border-radius: 0.25rem;
  }



}

.line {
  height: 0.25rem;

  margin-top: 0.10rem;
  margin-left: 0.25rem;
  border-radius: 0.1rem;
  margin-right: 0.25rem;
}