// 
// user.scss
// Use this to write your custom SCSS
//
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';


//font-size

.font-size-20 {
  font-size: $font-size-20 !important;
}

.font-size-base {
  font-size: $font-size-base !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-xs {
  font-size: $font-size-xs !important;
}

.font-size-xsm {
  font-size: $font-size-xsm !important;
}

.font-size-11 {
  font-size: 0.6875rem !important;
}

.font-size-13 {
  font-size: 0.813rem !important;
}

.font-size-32 {
  font-size: 2rem !important;
}

.font-size-xxsm {
  font-size: 0.5rem !important;
}


.pl-16 {
  padding-left: 1rem !important;
}

.pl-8 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  margin-left: .25rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.ml-14 {
  margin-left: .875rem !important;
}

.ml-12 {
  margin-left: .75rem !important;
}

.m-16 {
  margin: 1rem
}

.ml-16 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: .5rem !important;
}

.ml-2 {
  margin-left: .25rem !important;
}

.mr-2 {
  margin-right: .25rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-8 {
  margin-right: .5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.ml-32 {
  margin-left: 2rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-16 {
  margin-top: 1rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pt-32 {
  padding-top: 2rem;
}

.mt-32 {
  margin-top: 2rem;
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-1 {
  margin-top: 0.25rem
}

.pt-2 {

  margin-top: 0.25rem !important;
}

.pt-01 {
  padding-top: 0.1rem !important;
}

.pt-02 {
  padding-top: 0.2rem !important;
}


.pt-1 {

  margin-top: 0.124rem !important;
}

.pr-2 {
  padding-right: 0.25rem !important;
}


.pt-8 {
  padding-top: 1rem !important;
}

.pb-05 {
  padding-bottom: 0.125 !important;
}

.ph-05 {
  padding: 0.25 !important;
}

.pb-2 {

  margin-top: 0.25rem !important;
}

.text-left {
  text-align: left !important;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.font-size-22 {
  font-size: $font-size-22 !important;
}

.min-width-100 {
  min-width: 100%;
}

.page-heading {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: $black-9;
  font-weight: $font-weight-semi-bold;
}

hr {
  &.mb-12 {
    margin-block-end: .75rem;
  }
}

.main-content {
  padding-top: 3rem;
  background-color: $body-bg;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.text-black {
  color: $black !important;
}

.text-dark-primary {
  color: $black-14 !important;
}

.text-light {
  color: $gray-22 !important;
}

.text-light-25 {
  color: $gray-25 !important;
}

.text-disable {
  color: $gray-23 !important;
}

.text-disable-light {
  color: $gray-24 !important;
}

.bg-danger {
  background-color: $red-13 !important;
}

.loader-wrap {
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, .2);
}

.loader-wrap-white {
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (min-width: 992px) {
  .main-content {
    margin-top: 70px;
    >.container-fluid {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.line-hight-1 {
  line-height: 1rem;
}

.cl-white {
  color: white
}

.cl-black {
  color: $black-16;
}

.cl-gray {
  color: $gray-22
}

.success {
  background-color: $green-12;
}

.bg-white {
  background-color: $white;
}

.cl-transparent {
  color: transparent
}

.font-bold {
  font-weight: bold;
}

.cl-danger {
  color: $red-13 !important;
}

.bg-white-2 {
  background-color: $white-2 !important;
}

.border-radios-12 {
  border-radius: 0.75rem !important;
}

.border-radios-8 {
  border-radius: 0.25rem !important;
}

.cl-gray {
  color: $gray-20
}

.cl-gray23 {
  color: $gray-23
}

.cl-green {
  color: $green-16
}

.cl-green12 {

  text-align: center;
  align-self: center;
  color: $green-12;
  font-size: $font-size-base;
}

.cl-green_12 {

  text-align: center;
  align-self: center;
  color: $green-12;
}

.cl-secondary {
  color: $gray-23
}

.cl-black-9 {
  color: $black-9
}

.font-weight-bold {
  font-size: bold;
}

.font-color-black {
  color: $black-16
}

.w-80 {
  width: 80%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}


.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

.w-30 {
  width: 30%;
}

.w-70-i {
  width: 70% !important;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.text-underline {
  text-decoration: underline !important;
}

.mb-24 {
  margin-bottom: 1.5rem;
}