.dropbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #3e8e41;
}

.dropdown-m {
    float: right;
    position: relative;
    display: inline-block;
    margin: 0.8rem 0.6rem;
    z-index: 1000004;
}

.dropdown-content {
    position: absolute;
    background-color: $white;
    z-index: 1;
    padding: .25rem;
    border-color: $gray-16;
    box-shadow: 0 .25rem .5rem rgba($black, 0.1), 0 .0625rem 0.1875rem rgba($black, 0.1);
    border-radius: 6px;
    min-width: calc(100% - .5rem);
    max-height: 500px;
}

.dropdown-content-menu {
    overflow-y: scroll;
    max-height: 400px;
}

.dropdown-content-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content-item-text {
    color: $gray-23;
    font-weight: $font-weight-400;
    font-size: $font-size-sm;
    margin-left: 0.2rem;
}


.dropdown-content-item-icon {
    color: $gray-25;
    font-size: $font-size-sm;
}

.dropdown-content-menu::-webkit-scrollbar {
    width: 0;
    height: 0;
}


.dropdown-content-item:hover {
    background-color: #ddd;
}

.show {
    display: block;
}