.badge-pill {
  border-radius: 1.25rem;
}

.badge {
  line-height: 1rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
  font-weight: $font-weight-normal;

}

.badge-secondary {
  color: $gray-23
}

.badge-gray {
  background-color: $gray-21;
  color: $white;
}

.badge-gray-bg-w {
  color: $black-10;
}

.badge-danger {
  color: $white;
  background-color: $red-13;
}

.badge-danger-lighter-bg-w {
  color: $yellow-13;
}

.badge-danger-lighter {
  color: $yellow-12;
  background-color: $orange-light;
}

.badge-danger-light-bg-w {
  color: $red-13;
}


.badge-danger-light {
  color: $red-13;
  background-color: $red-dark-light;
}

.badge-success {
  color: $white;
  background-color: $green-12;
}

.badge-primary {
  background-color: $blue-15;
  color: $white;
}

.badge-primary-bg-w {
  color: $blue-15;
}

.badge-dark {
  background-color: $secondary;
  color: $white;
}

.badge-warn-lighter-bg-w {
  color: $blue-14;
  border-radius: 50%;
  background: #F0F8FF;
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 0.2rem;
  margin-left: 0.2rem;
}

.badge-number-indicator {
  font-size: $font-size-xsm;
  font-weight: $font-weight-medium;
  background-color: $green-12;
  color: $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.tooltip-inner {
  background-color: $gray-25;
  text-align: left;
  color: white;
  font-size: $font-size-sm;
  max-width: none;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $gray-25;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $gray-25;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $gray-25;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $gray-25;
}