.photo-upload-text-container {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // background-color: red;
}

.photo-drawer {
    padding-left: 0 !important;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    &.modal {
        overflow: unset;

        &.fade {
            .modal-dialog {
                transform: translate(0, 100%);
            }
        }

        &.show {
            .modal-dialog {
                transform: none;
            }
        }
    }

    .modal-dialog {
        margin: 0 auto;
    }

    .modal-content {
        border-radius: 1.5rem 1.5rem 0 0;
    }

    .modal-header {
        padding: 3rem 1.5rem 1.5rem;
    }

    .close {
        opacity: 1;
    }

    .modal-body {
        padding: 1.5rem;
        height: auto;
        overflow: auto;
    }
}

.photo-modal .modal-content {
    background-color: transparent;
    color: #fff;
    border: 0px solid black
}

.content-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    background-color: black;

}

.images-container {
    width: 100%;
    display: flex;
    flex: 0.15;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: -.25rem;
    margin-right: -.25rem;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .avatar {
        margin-left: .25rem;
        margin-right: 0.25rem;
        border-radius: .25rem;
        overflow: hidden;
        padding-bottom: 0.10rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


}

.images-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.image-container {
    flex: 0.75;
    // height: calc(100% - 9.5rem);
    width: 100%;

    // border: 5px solid white;
    background-size: 20px 20px;
}


.button-container {
    display: flex;
    flex: 0.10;
    width: 100%;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.custom-done-button {
    width: 90%;
    height: auto;
}

.webcam-container {
    width: 100%;
    height: 100%;
}

.webcam {
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.photo-list {
    flex-direction: row;

}

.camera-button-container {
    position: absolute;
    bottom: 10%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.camera-button {
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 50%;
    margin: 4rem;
}


.corners {
    position: relative;
    width: 50px;
    /* for demo purposes */
    padding: 10px;
}

.top,
.bottom {
    position: absolute;
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.top {
    top: 0;
    border-top: 1px solid;
}

.bottom {
    bottom: 0;
    border-bottom: 1px solid;
}

.left {
    left: 0;
    border-left: 1px solid;
}

.right {
    right: 0;
    border-right: 1px solid;
}

ul.images {
    margin: 0;
    white-space: nowrap;
    overflow-x: auto;
    // height: calc(100% - 9.5rem);
    width: 95%;


}

.asset-images {
    margin: 0;
    white-space: nowrap;
    overflow-x: auto;
    // height: calc(100% - 9.5rem);
    width: 95%;
    align-items: center;
    display: flex;


}

.asset-images::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.asset-image-container {

    display: flex;
    align-self: center;
    align-items: center;
}

.photo {
    margin: 0 .25rem 1rem;
    border-radius: .25rem;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.photo-80 {
    width: 4.75rem;
    height: 4.75rem;
    border-radius: 4px;
}

.action-button-container-90 {
    width: 100%;
    position: absolute;
    top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.action-button-container {
    width: 95%;
    display: flex;
    // background-color: red;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.action-button {
    padding: 1rem;
    border-radius: 4px;
    background-color: rgba($black, .08);
    border-width: 0px;
    margin-top: 2rem;
    backdrop-filter: blur(16px);
}


.icon-color {
    color: white;
    font-size: 2rem;
}

@media (max-width: 420) {
    .camera-button {
        height: 2.375rem;
        width: 2.375rem;
        border-radius: 50%;
        margin: 2rem;
    }

    .icon-color {
        color: white;
        font-size: 1rem;
    }

    .action-button {
        padding: 0.5rem;
        border-radius: 4px;
        background-color: rgba($black, .08);
        border-width: 0px;
        margin-top: 2rem;
        backdrop-filter: blur(16px);
    }
}

@media (min-width:800px) {

    .images-container {
        width: 100%;
        display: flex;
        flex: 0.15;
        height: 20%;

    }

    .image-container {
        height: 70%;

        // height: calc(100% - 9.5rem);
        width: 100%;

        // border: 5px solid white;
        background-size: 20px 20px;
    }

    .button-container {
        display: flex;
        height: 10%;
        width: 100%;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

}

.img-container {
    width: 100%;
    // display: flex;
    height: 100%;
    object-fit: cover;
    position: relative;

    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;
    // align-items: center;
    // border-radius: 10px;
    img {
        width: 100%;
        height: 100%;
    }

}

.img-container .progress {
    width: 100%;
    height: 100%;
    background-color: red;
    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;
    // align-items: start;
    // padding: 20px;
    // background: #e6e9ff;
    // border-radius: 20px;
    // box-shadow: 0px 10px 50px #abb7e9;
}

.img-parent {
    position: relative;
    border: 1px solid rgba($black, .08);
}

.red1 {
    width: 100%;
    height: 100%;
    background-color: red;
    z-index: 9;
}

.upload-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // background-color: rgba($black, 0.64);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
}

.circular-progress-bar {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: rgba($white, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;

}

.progress-text {
    // top: 0;
    // bottom: 0;
    // right: 0%;
    // left: 0%;
    width: 80%;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;


}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 60%;
    height: 60%;
}

.text-failed {
    color: $red-13;
    align-self: center;
    text-align: center;
    font-size: $font-size-sm;
    font-weight: $font-weight-400;
}

.text-success {
    text-align: center;
    align-self: center;
    color: $green-12;
    font-size: $font-size-base;
    font-weight: $font-weight-400;
}