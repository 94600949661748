.custom-calendar {
  &.react-datepicker {
    border: none;
  }

  .react-datepicker__navigation {
    top: 0;
  }

  .react-datepicker__navigation-icon::before {
    border-color: $green-12;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    padding-top: 0;
  }

  .react-datepicker__current-month {
    min-height: 2.8125rem;
    font-size: 1rem;
    color: $black-14;
    font-weight: $font-weight-semi-bold;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2.25rem;
    height: 2.25rem;
    margin: 0;
    line-height: 2.25rem;
    font-weight: 400;
  }

  .react-datepicker__day-name {
    font-size: $font-size-xs;
  }

  .react-datepicker__day {
    border-radius: 50%;
    font-size: $font-size-sm;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $green-12;
    font-weight: $font-weight-bold;
  }

  .react-datepicker__day-names {
    margin-top: .625rem;
  }
}

.date-picker-divider {
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 4.25rem;
    transform: translateX(-50%);
    border-bottom: .0625rem solid rgba($black, .12);
    width: 100%;
    height: .0625rem;
    z-index: 1;
  }
}

.date-picker-modal {
  &.custom-drawer {
    .modal-body {
      //min-height: 31.25rem;
      padding-bottom: 0;
      overflow: visible;
    }
  }
}

.date-picker-mobile {
  text-align: center;
}

.time-header {
  padding: 0 0 1.25rem;
  min-height: 2.8125rem;
}

.time-body {
  padding-top: 1.5rem;
  margin: 0 auto;
  width: 270px;

  .date-picker-mobile {
    text-align: center;
    padding: 7rem 0;
  }

  .react-datepicker-popper {
    padding: 0;
  }

  input {
    border-radius: .25rem;
    display: block;
    width: 100%;
    // height: 5rem;
    font-size: 3rem;
    color: $black-14;
    background-color: $green-15;
    border: .125rem solid $green-15;
    text-align: center;

    &:focus,
    &:active,
    &:focus-visible {
      border-color: $green-12;
      background-color: $white;
      outline: none;
    }
  }
}

.time-picker {
  .react-datepicker__triangle {
    display: none;
  }
}

@media (min-width: 992px) {
  .time-body {
    .date-picker-mobile {
      padding: 5rem 0;
    }
  }

  .date-picker-modal {
    &.custom-drawer {
      .modal-body {
        min-height: auto;
      }

      .modal-lg {
        max-width: 40.625rem;
      }
    }
  }
}