.make-ready-container {
    position: absolute;
    width: auto;
    // height: 100%;
    // top: 0px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 8rem;
    background-color: rgba(169, 157, 157, 0.12);
    backdrop-filter: blur(10px);

    // align-items: center;
}

.modal-box {
    box-sizing: border-box;

    /* Auto layout */
    padding: 3.125rem 3rem;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E6ECEF;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

@media (max-width:992px) {
    .modal-box {
        max-width: 90%;
    }

    .make-ready-container {
        width: 100%;
        left: 0rem;
    }

}

.modal-title-mr {
    font-size: 2rem;
    font-weight: $font-weight-semi-bold;
    color: $black-16;
    text-align: center;
}

.modal-body-mr {
    font-size: 1rem;
    font-weight: $font-weight-400;
    color: $black-16;
    text-align: center;
    width: 20.188rem;
}

.model-link-mr {

    font-size: 1rem;
    font-weight: $font-weight-400;
    color: $blue-14;
    text-align: center;
    text-decoration: underline;
}