.custom-dropdown {
  &.dropdown {
    .dropdown-toggle {
      position: relative;
      text-align: left;
      padding-right: 2.5rem;

      &:focus {
        box-shadow: none;
      }

      &:after {
        display: none;
      }
    }

    .btn-transparent {
      background-color: transparent;
      border-color: transparent;
      color: $dark;

      &:hover,
      &:active {
        background-color: $green-15;
        border-color: $green-15;
      }
    }

    &.show {
      .btn-toggle-lighter {
        &.dropdown-toggle {
          border-color: $blue-15;
          background-color: $green-15;
        }
      }

      .btn-transparent {
        background-color: $green-15;
        border-color: $green-15;
      }
    }
  }
}

.dropdown-menu {
  padding: .25rem;
  border-color: $gray-16;
  box-shadow: 0 .25rem .5rem rgba($black, 0.1), 0 .0625rem 0.1875rem rgba($black, 0.1);
  border-radius: 6px;
  max-height: 15rem;
  overflow-y: scroll;
}

// .dropdown-menu::-webkit-scrollbar {
//   /* WebKit */
//   // width: 1rem;
//   // height: 1rem;
// }


.dropdown-item {
  color: $black-12;
  font-size: $font-size-sm;
  line-height: 2.5rem;

  &:hover,
  &:active {
    background-color: $green-15;
    color: $dark;
  }
}

.toggle-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.btn-toggle-lighter {
  border-color: transparent;

  .toggle-icon {
    display: none;
  }

  &:hover {
    background-color: $gray-15;
    border-color: $gray-15;

    .toggle-icon {
      display: block;
    }
  }

  &:active,
  &:focus {
    border-color: $blue-15;
    background-color: $green-15;

    .toggle-icon {
      display: block;
    }
  }
}

//multiselect menu
.dropdown-menu {
  .custom-check {
    &.form-check {
      position: relative;
      padding-left: 0;

      .form-check-input {
        margin: 0;
        float: unset;
        position: absolute;
        left: .5rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .form-check-label {
        padding-left: 2rem;
      }
    }
  }
}

.select-option {
  color: $gray-22;
}

.TopNav_propertyDropdown__u3WkW {
  border: 0px
}