@font-face {
  font-family: $public-sans;
  src: url('#{$path-to-fonts}/Public-Sans/PublicSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $public-sans;
  src: url('#{$path-to-fonts}/Public-Sans/PublicSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $public-sans;
  src: url('#{$path-to-fonts}/Public-Sans/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $public-sans;
  src: url('#{$path-to-fonts}/Public-Sans/PublicSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $public-sans;
  src: url('#{$path-to-fonts}/Public-Sans/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}