.status-badge {
  border-radius: .375rem;
  font-size: $font-size-sm;
  padding: .5rem;
  font-weight: $font-weight-semi-bold;
}

.closed,
.canceled {
  background-color: $red-13;
}

.not-started {
  background-color: $green-15;
  color: $black-14;
}

.in-progress {
  background-color: $blue-11;
}

.on-hold {
  background-color: $yellow-13;
}

.completed {
  background-color: $success;
}

.info-card {
  min-height: 2.5rem;
}

.info-title {
  width: 6rem;
}

.info-action {
  width: calc(100% - 6rem);
}

.banner-label {
  background-color: $gray-15;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.card-action-divider {
  margin: .625rem 1rem;
  border-top: .0625rem solid $gray-15;
}

.bottom-tab-header-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-20;
}

.tab-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding: 6px 16px;
  gap: 4px;
  cursor: pointer;
}

.tab-item-selected {
  border-bottom: 1px solid $green-16;
  color: $green-16;
}

.btn-w100 {
  min-width: 6.25rem;
}

.modal-subheading {
  font-size: 1.375rem;
  margin-bottom: 2rem;
}

.has-clear-btn {
  position: relative;

  .clear-btn {
    display: none;
  }

  &:hover {
    .clear-btn {
      display: block;
    }
  }
}

.clear-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 100%;
  width: 2rem;

  &:hover {
    background-color: rgba($black, .1);
  }
}

@media (min-width: 768px) {
  .info-title {
    width: 10rem;
  }

  .info-action {
    width: calc(100% - 10rem);
  }
}

.time-card {
  margin-top: 2rem;
  border-top: 1px solid #C5CBD0;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.activity-container {
  max-height: 30rem;
  overflow-y: scroll;
}

.activity-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}