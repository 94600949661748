.modal-content {
  border-radius: .5rem;
  box-shadow: 0 .6875rem .9375rem -.4375rem rgba($black, 0.2), 0 1.5rem 2.375rem .1875rem rgba($black, 0.14), 0 0.5625rem 2.875rem .5rem rgba($black, 0.12);
}

.modal-header {
  padding: 1rem 1.5rem 1rem;
  border-bottom: none;
}

.modal-title {
  font-size: 1.25rem;
  color: $black-14;
}

.modal-body {
  padding: .5rem 1.5rem;
}

.modal-footer {
  border-top: none;
  padding: .5rem;

  >* {
    margin: 0;
  }
}

.modal-backdrop {
  z-index: 1055;
}

//side modal
.side-modal {
  top: 0;
  bottom: 0;
  height: 100%;

  &.fade-in {
    .modal-content {
      transform: translate(0, 0);
    }

    &.modal {
      &.show {
        .modal-dialog {
          transform: translate(-100%, 0);
        }

        .modal-content {
          transform: translate(0, 0);
        }
      }
    }
  }

  &.modal {
    overflow: unset;

    &.fade {
      .modal-dialog {
        transform: translate(-100%, 0);
      }
    }

    &.show {
      .modal-content {
        transform: translate(0, 0);
      }
    }
  }

  .modal-dialog {
    left: 100vw;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 41.5rem;
    margin: 0;
  }

  .modal-content {
    border: none;
    box-shadow: none;
    background-color: $white;
    border-radius: 0;
    height: 100%;
    transform: translate(100%, 0);
    transition: transform .3s ease-out;
    position: relative;
  }

  .close {
    line-height: 1.25rem;
  }

  .modal-title {
    line-height: 2rem;
    font-weight: $font-weight-semi-bold;
    font-size: 1.75rem;
  }

  .modal-body {
    padding: 1.5rem;
    height: auto;
    overflow: auto;
  }

  &.has-footer {
    .modal-body {
      max-height: calc(100% - 9.5rem);
    }
  }

  &.modal-header-footer {
    .modal-header {
      border-bottom: .0625rem solid $gray-19;
      box-shadow: 0 .0625rem .5rem rgba($dark, 0.1);
    }

    .modal-footer {
      border-top: .0625rem solid $gray-19;
      box-shadow: 0 .0625rem .5rem rgba($dark, 0.1);
    }
  }
}

//drawer
.custom-drawer {
  padding-left: 0 !important;
  top: unset;
  bottom: 0;
  height: auto;

  &.modal {
    overflow: unset;

    &.fade {
      .modal-dialog {
        transform: translate(0, 100%);
      }
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }

  .modal-dialog {
    margin: 0 auto;
  }

  .modal-content {
    border-radius: 1.5rem 1.5rem 0 0;
    max-height: calc(100vh - 8.125rem);
  }

  .modal-header {
    padding: 3rem 1.5rem 1.5rem;
  }

  .close {
    opacity: 1;
  }

  .modal-body {
    padding: 1.5rem;
    height: auto;
    overflow: auto;
  }
}

.centered {
  top: 0;
  bottom: 0;
  padding-right: 0 !important;

  &.modal {
    overflow: unset;
  }

  .modal-dialog {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .modal-content {
    border: none;
    background-color: $white;
    border-radius: .375rem;
    height: 100%;
    transition: transform .3s ease-out;
    position: relative;
  }

  .modal-title {
    line-height: 2rem;
    font-weight: $font-weight-semi-bold;
    font-size: 1.75rem;
  }

  .modal-body {
    padding: 1.5rem;
    height: auto;
    overflow: auto;
  }

  .modal-header {
    border-bottom: .0625rem solid $gray-19;
    box-shadow: 0 .0625rem .5rem rgba($dark, 0.1);
  }

  .modal-footer {
    border-top: .0625rem solid $gray-19;
    box-shadow: 0 .0625rem .5rem rgba($dark, 0.1);
  }
}

//responsive
@media (min-width: 576px) {
  .centered {
    .modal-dialog {
      max-width: 33rem;
    }

    .modal-content {
      height: calc(100% - 8rem);
    }
  }

  .custom-drawer {
    .modal-sm {
      max-width: 32.5rem;
    }
  }
}

@media (min-width: 992px) {
  .custom-drawer {
    top: 50%;
    bottom: unset;
    transform: translate(0, -50%);

    &.modal {
      &.fade {
        .modal-dialog {
          transform: translate(0, -50%);
        }
      }

      &.show {
        .modal-dialog {
          transform: none;
        }
      }
    }

    .modal-dialog {
      margin: 0 auto;
    }

    .modal-content {
      border-radius: .25rem;
      max-height: calc(100vh - 3rem);
    }

    .modal-body {
      padding: 1.5rem;
      height: auto;
      overflow: auto;
    }
  }
}

.modal-header-box {
  background-color: rgba($color: $black-16, $alpha: 0.02);
}

.pwa-popup-modal {
  background-color: rgba(242, 254, 248, 0.9);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

@media (min-width: 992px) {
  .pwa-popup-modal {

    background-color: rgba(242, 254, 248, 0.9);
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    align-self: center;
  }
}

.custom-drawer-content {
  max-height: 15rem;
  overflow: scroll;
  width: 100%;
}


.custom-drawer-content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.modal {
  --bs-modal-border-color: 'transparent'
}