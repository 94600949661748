.navbar {
  .navbar-toggler-icon {
    display: none;
  }
}

.navbar-light {
  .navbar-toggler {
    color: rgba($black, .87);
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.navbar-nav {
  background-color: $white;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.kanban-card-nav {
  .btn-nav {
    padding-left: 1.25rem;
  }
}

.btn-nav {
  color: $dark;
  padding-top: .625rem;
  padding-bottom: .625rem;
  text-align: left;

  &.active {
    position: relative;
    background-color: $success;
    color: $white;

    &:hover {
      color: $white;
    }

    &:before {
      content: '';
      position: absolute;
      right: calc(100% + .5rem);
      top: 0;
      height: 100%;
      width: .25rem;
      border-top-right-radius: .375rem;
      border-bottom-right-radius: .375rem;
      background-color: inherit;
    }
  }
}

.sidebar-collapsed {
  .btn-nav {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .btn-icon-left {
    .icon-left {
      left: .75rem;
    }
  }

  .custom-dropdown {
    &.dropdown {
      .dropdown-toggle {
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }
  }
}

.nav-container {
  min-height: 2.5rem;
}

.nav-menu {
  position: relative;
  z-index: 1031;
  margin-top: .5rem;
  height: 100%;
}

@media (min-width: 992px) {
  .navbar {
    [class*="container-"] {
      padding: 0;
    }
  }

  .navbar-nav {
    padding-top: 0;
    padding-bottom: .75rem;
  }
}

.offcanvas-body {
  padding: 0;
}