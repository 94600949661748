@import "../../assets/styles/react-variables";
@import "../../assets/styles/user-variables";
@import '../../assets/styles/mixins';

.columnHeading {
  font-size: 1rem;
}

.gray25 {
  color: $gray-25
}

.gray22 {
  color: $gray-22
}

.blue15 {
  color: $blue-15;
}

.red12 {
  color: $red-12;
}

.yellow12 {
  color: $yellow-12
}

.jobTitle {
  font-size: $font-size-base;
  color: $black-14;
}

.jobTitleLimited {
  font-size: $font-size-base;
  color: $gray-23;
}


:global(.calendarIcon) {
  color: $green-11;
}

:global(.badge-danger) {
  .calendarIcon {
    color: white;
  }
}

.jobDescription {
  line-height: 1.25rem;
}

.openWo {
  padding: 0 48px;
  @include mediaSm{
    padding: 10px;
}
}

.card {
  padding: .625rem;
  border-radius: 14px;
  box-shadow: 0px 1px 24px 0px #CB3C230A;
  
  .editIcon {
    position: absolute;
    right: .5rem;
    top: .5rem;
    display: none;
  }

  &:hover {
    border-color: $gray-24;
    box-shadow: 0px 0.063px 0.5px rgba(23, 23, 28, 0.1);

    border-radius: 5px;

    .editIcon {
      display: block;
    }
  }
}

.draggingCardWrap {
  .card {
    box-shadow: 0 .9375rem 3.125rem rgba(23, 23, 28, 0.3);
    background-color: $white;
    border-radius: 5px;
    transform: rotate(-7deg);
    border-color: $gray-22;
  }
}

.cardBody {
  padding-bottom: .375rem;
}

.cardFooter {
  border-top-color: $gray-19;
  margin: 0 .5rem;
  padding: .375rem .5rem .75rem;
}

.inProgress {
  color: $blue-15;
}

.cardHighlight {
  background-color: $white;
  border: 2px dashed $blue-15;
  box-shadow: 0px 1px 8px rgba(23, 23, 28, 0.1);
  border-radius: 4px;
}


.bg_white_32 {
  background-color: rgba(255, 255, 255, 0.32);
}