@mixin placeholder($color, $font-weight){
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
    font-weight: $font-weight;
  }
}

$screen-sizes: (
    "mediaSm": 576px,
    "mediaMd": 768px,
    "mediaLg": 992px,
    "mediaXl": 1200px
);

@function screen($screen-size) {
  @return map-get($screen-sizes, $screen-size);
}

@mixin mediaSm {
  @media (max-width: screen(mediaSm)) {
      @content;
  }
}

@mixin mediaMd {
  @media ((min-width: screen(mediaSm)) and (max-width: screen(mediaMd))) {
      @content;
  }
}

@mixin mediaLg {
  @media ((min-width: screen(mediaMd)) and (max-width: screen(mediaLg))) {
      @content;
  }
}

@mixin mediaXl {
  @media (min-width: screen(mediaXl)) {
      @content;
  }
}

@mixin chart-body {
  width: 100%;
  padding: 40px;
  color: #1B1B1B;
  background: #FFFFFF;
  @include border-style;

  @include mediaSm {
      padding: 20px 10px;
      margin: 20px 0 20px 0;
  }
}

@mixin border-style {
  border: 1px solid rgba(#6F6B74, 0.24);
  border-radius: 14px;
  box-shadow: 0px 2px 12px rgba(27, 27, 27, 0.04);
}


@mixin box-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 0 8px 0 8px;
  @include border-style;
}

@mixin header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1.4rem;
  width: 100%;

  @include mediaSm {
      flex-direction: column;
      align-items: start;
  }

  @include mediaMd {
      flex-direction: column;
      align-items: start;
  }
}

@mixin title {
  color: #1B1B1B;
  font-size: large;
  font-weight: bold;
}

@mixin scroll-snap-x {
   /* browser decide if x is ok to scroll and show bars on, y hidden */
   overflow: auto hidden;
   /* prevent scroll chaining on x scroll */
   overscroll-behavior-x: contain;
   /* scrolling should snap children on x */
   scroll-snap-type: x mandatory;
 
   @media (hover: none) {
     scrollbar-width: none;
 
     &::-webkit-scrollbar {
       width: 0;
       height: 0;
     }
   }
}

@mixin custom-scrollbar {
  /* For webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 4px; /* Adjust width of scrollbar */
  }

  ::-webkit-scrollbar-track {
      background: none; /* Default background of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
      background: #888; /* Default background of the scrollbar thumb */
      border: 4px solid #ddd; /* Border of the scrollbar thumb */
      border-radius: 8px; /* Rounded corners for the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555; /* Background of the scrollbar thumb on hover */
  }
}